import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import { ProvideAuth } from '../../components/Auth/useAuth'
import HcpdPageHero from '../../components/HealthCareProviderDirectory/HcpdPageHero'
import ConfirmRegistration from '../../components/Auth/ConfirmRegistration'

export const query = graphql`
  {
    prismicHcpdConfirmRegistrationPage {
      data {
        body_text {
          richText
        }
        title {
          text
        }
      }
    }
  }
`

const ConfirmRegistrationPage = ({ data }) => {
  const doc = data.prismicHcpdConfirmRegistrationPage?.data
  if (!doc) {
    return null
  }

  return (
    <ProvideAuth>
      <Layout>
        <HcpdPageHero
          title={doc.title?.text ?? 'New Registration'}
          body={doc.body_text}
        />
        <ConfirmRegistration />
      </Layout>
    </ProvideAuth>
  )
}

export default ConfirmRegistrationPage
