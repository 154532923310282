import styled from 'styled-components'
import { DEVICE } from '../../../util/constants'
import { Link } from 'gatsby'

import { FormContainer } from './AuthFormCommon.styles'

export const ResetPasswordFormContainer = styled(FormContainer)`
  /* naming convenience */
`

export const ResetPasswordFields = styled.div`
  display: flex;
  flex-direction: column;

  @media ${DEVICE.tabletL} {
    flex-direction: row;
  }
`

export const CtaRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 2;
  letter-spacing: 0.02em;

  @media ${DEVICE.tabletL} {
    width: 450px;
  }
`

export const BackToLogin = styled(Link)`
  font-size: 1.4rem;
  line-height: 1;
  font-weight: bold;
  letter-spacing: 0.02em;
  text-decoration-line: none;
  color: var(--content-background-dark-blue);

  :hover {
    color: var(--highlight-blue);
  }
`
