import React from 'react'
import { useForm } from 'react-hook-form'
import { navigate } from 'gatsby'

import SubmitButton from './SubmitButton'
import { RegionContainer } from '../styles/Utility.styles'
import { AuthForm, FormContainer } from './styles/AuthFormCommon.styles'
import LabeledTextField from './LabeledTextField'
import { VALIDATION_RULES } from './validationRules'
import { CtaRow } from './styles/ResetPasswordForm.styles'
import { useAuth } from './useAuth'

const ConfirmRegistration = () => {
  const auth = useAuth()
  const { handleSubmit, register, setError, errors } = useForm()
  const onSubmit = values => {
    auth.confirmSignup(values.email, values.code).then(result => {
      if (result === 'SUCCESS') {
        navigate('/health-care-provider-directory/login')
      } else if (result.code === 'CodeMismatchException') {
        setError('code', {
          type: 'manual',
          shouldFocus: true,
          message: 'Invalid confirmation code.',
        })
      } else if (result.code === 'UserNotFoundException') {
        setError('email', {
          type: 'manual',
          shouldFocus: true,
          message: 'No user exists for this email address.',
        })
      } else if (result.code === 'NotAuthorizedException') {
        setError('email', {
          type: 'manual',
          shouldFocus: true,
          message: 'Email address already confirmed. Please go to login page.',
        })
      }
    })
  }

  return (
    <RegionContainer>
      <FormContainer>
        <AuthForm onSubmit={handleSubmit(onSubmit)}>
          <LabeledTextField
            label="Email"
            type="text"
            name="email"
            isRequired
            fieldWidth="47%"
            errors={errors.email}
            fieldRef={register(VALIDATION_RULES.email)}
          />
          <LabeledTextField
            label="Confirmation Code"
            type="text"
            name="code"
            isRequired
            fieldWidth="47%"
            errors={errors.code}
            fieldRef={register(VALIDATION_RULES.required)}
          />
          <CtaRow>
            <SubmitButton type="submit" text="Submit Confirmation Code" />
          </CtaRow>
        </AuthForm>
      </FormContainer>
    </RegionContainer>
  )
}

export default ConfirmRegistration
